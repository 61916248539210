<template>
  <div>
    <dashboard-page-title
      :showAddBtn="false"
      :showSearch="true"
      v-model="params.search"
      :dropdownList="dropdownList"
      @dropdownChanged="params.status = $event.value"
    >
      {{ $t("orders.orders") }}
    </dashboard-page-title>
    <main-table
      :fields="fields"
      list_url="merchant/orders"
      :params="params"
      :reloadData="reloadData"
      :paginationName="$t('orders.orders')"
    />

    <b-modal
      id="changeStatus"
      size="lg"
      :title="$t('main.status.changeStatus')"
      style="direction: rtl"
      centered
      hide-footer
      hide-header-close
    >
      <b-row class="d-flex flex-wrap">
        <b-col
          v-for="(status, key) in allStatus"
          :key="key"
          class="col-3 text-black text-center mb-3 cursor-pointer"
        >
          <span
            :class="[
              { 'shadow bg-primary text-white': isSelected(status.value) },
              'd-inline-block w-100 p-1 custom-rounded  primary-color ',
            ]"
            @click="selectedStatusId = status.value"
          >
            <i
              :class="[{ 'las la-check-square': isSelected(status.value) }]"
            ></i>
            {{ status.text }}
          </span>
          <span class="d-block w-100 h-10 bg-white"></span>
          <span :class="`${status.class} d-block w-100 h-10`"> </span>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-center mt-2">
        <b-button
          v-if="saveButton"
          class="btn btn-primary float-start"
          variant="primary"
          disabled
        >
          <spinner-loading text="Loading" />
        </b-button>
        <b-button
          variant="primary"
          v-else
          class="w-50"
          @click="saveEditStatus"
          >{{ $t("main.save") }}</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { core } from "@/config/pluginInit";
import orderService from "../services/order";

export default {
  components: {},
  mounted() {
    core.index();
  },
  data() {
    return {
      reloadData: false,
      allStatus: [
        {
          text: this.$t("main.status.in_progress"),
          value: "in_progress",
          class: "status--preparation",
        },
        {
          text: this.$t("main.status.shipping_to_order"),
          value: "shipping_to_order",
          class: "status--on_delivery",
        },
        {
          text: this.$t("main.status.rejected"),
          value: "rejected",
          class: "status--refused",
        },
        {
          text: this.$t("main.status.delivered"),
          value: "delivered",
          class: "status--success",
        },
      ],
      saveButton: false,
      selectedStatusId: "",
      orderId: null,
      fields: [
        { label: "Id", key: "id", class: "text-start", sortable: true },
        {
          label: this.$t("main.customerName"),
          key: "customer.name",
          class: "text-start",
        },
        {
          label: this.$t("orders.number_of_products"),
          key: "order_products",
          class: "text-start",
          type: "func",
          func: (items) => items?.order_products?.length ?? 0,
        },
        {
          label: this.$t("main.address"),
          key: "customer_address.address",
          class: "text-start",
        },
        {
          label: this.$t("main.phone"),
          key: "customer_address.phone",
          class: "text-start",
        },
        {
          label: this.$t("main.status.status"),
          key: "status",
          type: "status",
          objectTranslation: "main.status",
          class: "text-start",
        },
        {
          label: this.$t("orders.total_price"),
          key: "total_amount",
          class: "text-start",
        },
        {
          label: this.$t("orders.order_date"),
          key: "created_at",
          class: "text-start",
          type: "dateTime",
        },
        {
          label: this.$t("products.control"),
          key: "actions",
          class: "text-start",
          type: "actions",
          actions: [
            {
              text: this.$t("main.view"),
              icon: "ri-eye-fill",
              color: "info",
              ifNavigate: true,
              routeName: "orders.showOrder",
              routeParams: ["id"],
              showIf: () => this.$perHelper("orders:findOne"),
            },
            {
              text: this.$t("main.changeStatus"),
              icon: "las la-sync",
              color: "primary",
              actionName: "showPopupChangeStatusOrder",
              actionParams: ["id"],
              showIf: () => this.$perHelper("orders:update"),
            },
            {
              text: this.$t("main.download"),
              icon: "las la-receipt",
              color: "success",
              ifNavigate: true,
              routeName: "invoice",
              routeParams: ["id"],
              showIf: () => this.$perHelper("orders:findOne"),
            },
          ],
        },
      ],
      params: { status: "", search: "" },
      dropdownList: [
        {
          selected: "",
          options: [
            { title: this.$t("main.status.all"), value: "" },
            {
              title: this.$t("main.status.in_progress"),
              value: "in_progress",
            },
            {
              title: this.$t("main.status.shipping_to_order"),
              value: "shipping_to_order",
            },
            {
              title: this.$t("main.status.rejected"),
              value: "rejected",
            },
            {
              title: this.$t("main.status.delivered"),
              value: "delivered",
            },
          ],
        },
      ],
    };
  },
  methods: {
    saveEditStatus() {
      this.saveButton = true;
      orderService
        .changeOrderStatus(this.orderId, this.selectedStatusId)
        .then((res) => {
          core.showSnackbar("success", "تم تغير حالة الطلب بنجاح");
          this.reloadData = true;
          this.saveButton = false;
          this.$bvModal.hide("changeStatus");
        })
        .then(() => {
          this.reloadData = false;
        });
      this.reloadData = false;
    },
    isSelected(value) {
      return value === this.selectedStatusId;
    },
    showPopupChangeStatusOrder(data) {
      this.orderId = data.id;
      this.$bvModal.show("changeStatus");
    },
  },
  created() {
    this.$root.$on(
      "showPopupChangeStatusOrder",
      this.showPopupChangeStatusOrder
    );
  },
};
</script>
